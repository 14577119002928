  #header {


      padding-top: 5rem;
      padding-bottom: 5rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      position: relative;
      pointer-events: none;


  }


  .header2_main {

      padding-top: 9rem;
      padding-bottom: 3rem;


  }


  .header2-text-wrapper {
      display: flex;
      flex-direction: column;
       align-items: center; 
  }


  .header2-text-wrapper h1 {
      font-family: var(--font-family);
      color: #000;
      margin: 0;
      text-align: center;

  }

  .header2-text-wrapper h1.title1 {

    font-size: 5.5rem;
        font-weight: 600;
    letter-spacing: -0.02em;
        line-height: 1.05;
        margin-bottom: 40px;
       color: var(--color-logo-blue); 

  }

  .header2-text-wrapper h1.title2 {

    

    font-size: 2.25rem;
    line-height: 3rem;
    letter-spacing: normal;
    font-weight: 400;
    max-width: 60%;

     
  }


  .nowrap {
      white-space: nowrap;
  }

  .header2-right {

      display: flex;
      flex-direction: column;

  }

  .header2-img-wrapper {

      display: flex;

      flex-direction: column;

      align-items: center;

  }

  .header2-img-wrapper img {


      width: 80%;
      height: auto;
      pointer-events: none;
  }

  .animation-wrapper{
    animation-delay: 2s,100ms;
    animation-name: wrapper;
    animation-duration: 800ms,100ms;
    animation-timing-function: cubic-bezier(0.35, 0, 0.44, 1) , cubic-bezier(0.4, 0, 1, 1);
    animation-fill-mode: both;
  }
@keyframes wrapper {
    from {
        
        transform: translateY(80px);
       
    }

    to {
       
        transform: translateY(0);
    }

}
 .animation-wrapper1{
    animation-name: wrapper1a, wrapper1b;
    animation-fill-mode: both;
    animation-duration: 1333ms,100ms,1133ms,100ms;
    animation-delay:133ms,100ms,333ms,100ms;
    animation-timing-function: cubic-bezier(0.35, 0, 0.52, 1), cubic-bezier(0.4, 0, 1, 1);
 }
 @keyframes wrapper1a {
     from {
         opacity: 0;
     }

     to {
         opacity: 1;
     }

 }
 @keyframes wrapper1b {
    from{
        transform: translateY(15px);
    }
    to{
        transform: translateY(0);
    }
    
 }
 

 .animation-wrapper2 {
    animation-name: wrapper2a, wrapper2b;
    animation-fill-mode: both;
    animation-duration: 900ms,100ms,733ms,100ms;
    animation-delay: 2.1s, 100ms,2367ms, 100ms;
    animation-timing-function: cubic-bezier(0.17, 0, 0.83, 1), linear, cubic-bezier(0.4, 0, 1, 1);
 }
 @keyframes wrapper2a {
     from {
         opacity: 0;
     }

     to {
         opacity: 1;
     }

 }

 @keyframes wrapper2b {
     from {
        transform: translateY(4px);
     }

     to {
        transform: translateY(0);
     }

 }


 

@media (max-width: 1399.98px) {
    .header2-text-wrapper h1.title2 {
           
           
            max-width: 70%;
            
        }
}

  @media (max-width: 1199.98px) {

      .header2_main {

          padding-top: 9rem;
          padding-bottom: 2rem;

      }

          .header2-text-wrapper h1.title1{
            margin-bottom: 24px;
          }


      .header2-text-wrapper h1.title2 {
          font-size: 2.125rem;
          line-height: 2.875rem;
          letter-spacing: normal;
          font-weight: 400;
       
        max-width: 75%;
        
      }


  }

  @media (max-width: 991.98px) {

    .header2-text-wrapper h1.title1 {
            font-size: 4.5rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.02rem;
    
    
        }
    .header2-text-wrapper h1.title2 {
            font-size: 1.875rem;
            line-height: 2.5rem;
            letter-spacing: normal;
            font-weight: 400;
           
            max-width: 90%;
            
        }
  }

  @media (max-width: 767.98px) {
     @keyframes wrapper {
            from {
                
                 transform: translateY(30px); 
            }
    
            to {
    
                transform: translateY(0);
            }
    
        } 

    .header2-text-wrapper{
        align-items: flex-start;
    }
    .header2-text-wrapper h1 {
    
            text-align: left;
    
        }
    
    .header2-text-wrapper h1.title1 {
            font-size: 4rem;
            font-weight: 600;
            line-height: 4.5rem;
            letter-spacing: -0.02rem;
          
    
        }
    .header2-text-wrapper h1.title2 {
       
             font-size: 1.75rem;
            line-height: 2.375rem;
            letter-spacing: normal;
            font-weight: 400; 
            max-width: 100%;
    
        }
  }


  @media (max-width: 575.98px) {

      .header2_main {

          padding-top: 7rem;
          padding-bottom: 2rem;
      }

        
          
                  .header2-text-wrapper h1.title1 {
                      font-size: 3.5rem;
                       line-height: 4rem; 
                      letter-spacing: -0.02rem;
        
        
                  }
                  
                   .header2-text-wrapper h1.title2 {
        
                  
                       font-size: 1.375rem;
                       font-weight: 400;
                       letter-spacing: normal;
                       line-height: 1.875rem; 
                        max-width: 100%;
                   }

  }

 @media (max-width: 390.98px){

     .header2-text-wrapper h1.title1 {
            font-size: 3.125rem;
            line-height: 3.5rem;
        } 

}
   