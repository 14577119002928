#partners2 {

    padding-top: 6rem;
    padding-bottom: 4rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

}


.partners2-title-main h4 {
    margin: 0;
    font-family: var(--font-family);
    color: #000;

    /* font-size: 2.375rem;
    font-weight: 500;
    line-height: 2.75rem;
    letter-spacing: -0.01375rem; */

    /* fluid-paragraph-01 sm */
        /* font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.875rem; */

        font-size: 1.625rem;
            font-weight: 500;
            letter-spacing: -.01625rem;
            line-height: 1.875rem;

}

/* .partners2-title-wrapper {
    margin-bottom: 32px;
} */

.partners2-title-main .partners2-subtitle {
    font-family: var(--font-family);
    color: #000;

    
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: -0.01375rem;

    /* fluid-quotation-01 sm */
        /* font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.625rem; */

    

}

.partners2-logo-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

}

.partners2-logo-wrapper {
    padding-right: 26px;
    margin: 64px 0 0 0;
    width: 16.6%;
}

.partners2-logo-wrapper2 {
    padding-right: 0px;
    margin: 64px 0 0 0;
    width: 16.6%;
}
  .partners2-link-wrapper2{
    margin-left: 12px;

}  

.partners2-logo-wrapper3 {
    padding-right: 0px;
    margin: 64px 0 0 0;
    width: 16.6%;
}

.partners2-link-wrapper3{
    margin-left: 12px;
}


.partners2-filter-main .partners2-filter-img-polygon {
    max-width: 100%;
    width: 7.5rem;
    height: auto;

}

.partners2-filter-main .partners2-filter-img-telos {
    max-width: 100%;
    width: 6rem;
    height: auto;
    margin-bottom: 0.3rem;

}

.partners2-filter-main .partners2-filter-img-risczero {
    max-width: 100%;
    width: 5.2rem;
    height: auto;

}

.partners2-filter-main .partners2-filter-img {
    max-width: 100%;
    width: 8.6rem;
    height: auto;

    
}

.partners2-filter-main .partners2-filter-img-cvlabs {
    max-width: 100%;
    width: 6.5rem;
    height: auto;

}

.partners2-filter-main .partners2-filter-img-ef {
    max-width: 100%;
    width: 9.9rem;
    height: auto;
    margin-bottom: 0.1rem;

}

.animation-element {
    animation-duration: 0.8s;
    animation-name: followUp;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0, 0.07, 0.91);
}

@keyframes followUp {
    from {
        opacity: 0;
        transform: translateY(5vh);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }

}


@media (max-width: 1399.98px) {
    .partners2-filter-main .partners2-filter-img-risczero {
        max-width: 100%;
        width: 5rem;
        height: auto;

    }
}

@media (max-width: 1199.98px) {

    #partners2 {
    
            padding-top: 5rem;
            padding-bottom: 4rem;
    
    
        }
    .partners2-filter-main .partners2-filter-img-risczero {
            max-width: 100%;
            width: 4.4rem;
            height: auto;
    
        }
}

@media (max-width: 991.98px) {
    #partners2 {
    
            padding-top: 4rem;
            padding-bottom: 4rem;
    
    
        }
    .partners2-filter-main .partners2-filter-img-risczero {
        max-width: 100%;
        width: 4rem;
        height: auto;

    }

        .partners2-filter-main .partners2-filter-img-cvlabs {
            max-width: 100%;
            width: 5.5rem;
            height: auto;
    
        }

  .partners2-link-wrapper3 {
      margin-left: 6px;
  }
}


@media (max-width: 767.98px) {

    #partners2 {
    
            padding-top: 3rem;
            padding-bottom: 3rem;
           
    
        }

    .partners2-logo-main {

        justify-content: flex-start;

    }

    .partners2-title-main h4 {

        /* font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.625rem; */

        /* fluid-heading-03 sm */
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.75rem;

    }

    .partners2-title-main .partners2-subtitle {

        /* font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5rem; */

        /* body-02 */
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.5rem;

    }

    .partners2-logo-wrapper {
        padding-right: 16px;
        margin: 24px 0 0 0;
        /* width: 25%; */
        width: 33.33%;
    }

        .partners2-logo-wrapper2 {
            padding-right: 16px;
            margin: 24px 0 0 0;
            /* width: 25%; */
            width: 33.33%;
        }

                 .partners2-link-wrapper2 {
                     margin-left: 0px;
        
                 }
                 .partners2-logo-wrapper3 {
                     padding-right: 16px;
                     margin: 24px 0 0 0;
                     /* width: 25%; */
                     width: 33.33%;
                 }
                
              .partners2-link-wrapper3 {
                 margin-left: 0px;
             }



    /* .partners2-title-wrapper {
        margin-bottom: 16px;
    } */

    .partners2-filter-main .partners2-filter-img-polygon {
        max-width: 100%;
        width: 7rem;
        height: auto;

    }

    .partners2-filter-main .partners2-filter-img-telos {
        max-width: 100%;
        width: 6rem;
        height: auto;

    }

    .partners2-filter-main .partners2-filter-img-risczero {
        max-width: 100%;
        width: 4rem;
        height: auto;

    }

    .partners2-filter-main .partners2-filter-img {
        max-width: 100%;
        width: 7.9rem;
        height: auto;

        /* filter: grayscale(100%);
    transition: filter .3s ease-in-out; */
    }

    .partners2-filter-main .partners2-filter-img-cvlabs {
        max-width: 100%;
        width: 5.5rem;
        height: auto;

    }

    .partners2-filter-main .partners2-filter-img-ef {
        max-width: 100%;
        width: 9rem;
        height: auto;

    }

}