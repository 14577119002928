 #about{
    padding-top: 0rem;
    padding-left: 0.75rem;
        padding-right: 0.75rem;
 }
 
 .about-section-main{

    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        
        padding-top: 2rem;
        padding-bottom: 3rem;
 }
 .about-main-title {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
    
     
     padding-top: 3rem;
     padding-bottom: 3rem;
 }

 .about-main-title h2 {
     margin: 0;
     
     font-family: var(--font-family);
   
     color: #000;
           /* fluid-heading-05 xlg */
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.5rem;
   

 }

 /* .about-section-title {
     position: relative;
 }

 .about-section-title::after {
     position: absolute;
     top: 3.6rem;
     left: 0rem;
     content: '';
     display: block;
     height: 0.25rem;
     width: 4.5rem;
     background-color: rgba(0, 0, 0);
 }

 .about-section-title:hover::after {
     -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
     animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

     -webkit-transform: scaleX(1.5);
     transform: scaleX(1.5);
     -webkit-transform-origin: 0% 0%;
     transform-origin: 0% 0%;


 } */


.aboutus-after-text-row{
    display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1rem 2rem 1rem 2rem;
    
}

.aboutus-after-text-col{
    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0.5rem;
}

 

 .aboutus-text-main {
     display: flex;
     flex-direction: row;
     
        padding-top: 1.5rem;
    padding-bottom: 0.5rem;
   
    justify-content: center;

 }
 

 .aboutus-text-left {
     display: flex;
     flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;

     padding: 1rem 2rem 1rem 2rem;
      
 }
  .aboutus-text-left p{
    margin: 0;
 } 
 

 
 

 .aboutus-text-right {
     display: flex;
     flex-direction: column;

     padding-top: 1rem;
     padding-bottom: 1rem;
     background: #fff;
 }

 .aboutus-col {
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
     align-items: flex-start;
   
     padding-top: 0.5rem;
     padding-bottom: 0.5rem;

 }

 .aboutus-col p {
     margin: 0;
     color: #000;
     font-family: var(--font-family);
      padding-bottom: 0.3rem; 
    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.625rem;

 }
 .aboutus-after-logo {
    display: flex;
    flex-direction: row;
     justify-content: flex-start;
     
     padding: 1rem 2rem 1rem 2rem;
 }
 .aboutus-after-logo-text{
    display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem;
 }

 @media (max-width: 991.98px) {
    .aboutus-text-right {
           
            padding-top: 1rem;
            padding-bottom: 1rem;
            
        }
 }

@media (max-width: 767.98px) {
    .about-main-title h2 {
    
            /* fluid-heading-05 lg */
            font-size: 2.625rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.125rem;
    
    
        }

                .aboutus-col p {
                   
                    /* body-02 */
                        font-size: 1rem;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 1.5rem;
                    
                    
        
                }
}

