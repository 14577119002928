#projects {
    padding-bottom: 4.5rem;

}


.projects-row1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.projects-col1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
    background: #fff;
   
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.projects-col1 h2 {
    margin: 0;
    
    font-family: var(--font-family);
    
    color: #000;
            /* fluid-heading-05 xlg */
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.5rem;
   
}

/* .projects-col1-title {
    position: relative;
}

.projects-col1-title::after {
    position: absolute;
    top: 3.6rem;
    left: 0rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 4.5rem;
    background-color: rgba(0, 0, 0);
}

.projects-col1-title:hover::after {
    -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
} */



.projects-title {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}


 .projects-img-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: auto;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0));

} 

.projects2-owl-wrapper{
    display: flex;
    padding-left: 0.75rem;
        padding-right: 0.75rem;

}


.p-sl {
    display: flex;
    flex-direction: row;
    justify-content: center;
     /* align-items: center;  */
     align-items: flex-start;
    /* padding: 0.5rem; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}


.p-sl-img {
display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin: 0;
    /* padding: 1rem; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
   
   

}


.p-sl-img img {

        max-width: 70%;
        height: auto;
        pointer-events: none;
      
}

.owl-carousel .owl-item img.p-sl-img-telos{
        max-width: 60% !important;
        height: auto;
        pointer-events: none;
        margin-top: 1rem;

}
.owl-carousel .owl-item img.p-sl-img-ef {
    max-width: 70% !important;
    height: auto;
    pointer-events: none;
    margin-top: 1rem;

}

.p-sl-img .projects-polygon-text {
    font-family: var(--font-polygon) !important;
    
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;

}


.p-sl-img .projects-polygon-text span {
   
   
    background: var(--polygon-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.p-sl-img .projects-ponos-text {
    font-family: var(--font-family);

    font-size: 1.5rem;
    font-weight: 600;
   
    color: #000; 
   
   
}


.p-sl-img .projects-ponos-text span{
    
    color: #33b1ff;
    
}



.p-sl-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0.5rem; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: var(--font-family);
}

.p-sl-text h4 {
    /* padding: 1rem 0; */
    padding-bottom: 1rem;
  
    text-align: left;
    margin: 0;
    font-family: var(--font-family);
  
    color: #000;

    /* fluid-paragraph-01 sm */
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.875rem;
   
    
}

.p-sl-text p {
    padding: 0;
    
    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.625rem;
    
    font-family: var(--font-family);
    
    color: #000;
   

}

.p-sl-text .p1{
    margin: 0 0 1rem 0;

}
.p-sl-text .p2{
    margin: 0 0 0rem 0;
   
}

.owl-carousel.owl-loaded {
    cursor: grab;
}
.owl-carousel .owl-nav{
    cursor:default;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next{
    
    width: 36px !important;
    height: 36px !important;
  
}


 .owl-carousel .owl-nav button.owl-prev{
    
    background: url('../../assets/images/projects/left-t.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 50% !important;
    
    background-color: rgba(221, 225, 230, 0.5) !important;
    
}

.owl-carousel .owl-nav button.owl-next{
    
    background: url('../../assets/images/projects/right-t.svg') !important;
    background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
       border-radius: 50% !important;
        background-color: rgba(221, 225, 230, 0.5) !important;

        transition: background-color .3s ease-in-out;
        
}

 .owl-carousel .owl-nav button.owl-prev:hover {
    
   
       background-color: #F7F7F7 !important;
}

.owl-carousel .owl-nav button.owl-next:hover {
   

    background-color: #F7F7F7 !important;

      
} 

 


 .p-sl-text .p2 span{

     margin: 0;
     color: #000;
        font-family: var(--font-family);
      cursor: pointer;

 }
  .p-sl-text .p2 span a {
      text-decoration: underline;
    font-family: var(--font-family);
      color: #000;
  
        /* fluid-quotation-01 sm */
            font-size: 1.25rem;
            letter-spacing: 0;
            line-height: 1.625rem;
        font-weight: 500;
       
        transition: color .3s ease-in-out;

  }

  

 .p-sl-text .p2 span a:hover {
   
    color: #0F62FE;
    
 }
 

 .p-sl-text .p1 span {

     margin: 0;
     color: #000;
     font-family: var(--font-family);
     cursor: pointer;
    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        letter-spacing: 0;
        line-height: 1.625rem;
        font-weight: 500;

 }

 .p-sl-text .p1 span a {
     text-decoration: underline;
     font-family: var(--font-family);
     color: #000;
    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        letter-spacing: 0;
        line-height: 1.625rem;
        font-weight: 500;
    

 }

 .p-sl-text .p1 span a:hover {

     color: #0F62FE;

 }

 /* /////////////////////////////////////////////////// */

 .projects-form {
     font-family: var(--font-family);
     display: flex;
     flex-wrap: wrap;
     padding: 1rem 1.5rem 1rem 1.5rem;
     border-width: 1px;
     border-top-left-radius: 0.25rem;
     border-top-right-radius: 0.25rem;
     margin-left: 0;
     margin-right: 0;
 }
 .projects-form-end {
     
     padding-bottom: 1rem;

 }

.projects-form-end p{
    margin: 0;
    text-align: center;
    

}
 .form-demo-p1{
    margin-bottom: 0.5rem;
} 

input.demo-form-control.form-control{
    background-color: #ffffff !important;
}

 input.demo-form-control.form-control:focus {
     border: 1px solid;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    
} 



 .modal-subtitle {
     font-family: var(--font-family);
     color: #000;
     margin-bottom: 1.5rem;
 }

 div.demo-modal-header.modal-header {
     font-family: var(--font-family);
     color: #000;
     padding-left: 1.5rem !important;
     padding-right: 1.5rem !important;
     
 }

 /* ///////////////////////////////////////////////////// */


 button.nowrap.demo-btn.btn.btn-outline-secondary {
     font-family: var(--font-family) !important;
     font-weight: 500 !important;
     color: #000 !important;
     background-color: #fff !important;
     border-color: #000 !important;
     border-radius: 8px !important;
     box-shadow: none !important;
     padding: 0.5rem 1.5rem;
     
 }

 button.nowrap.demo-btn.btn.btn-outline-secondary:hover {
     background-color: #f7f7f7 !important;

 }

 button.btn-dark.btn.demo-close {
     border-radius: 8px !important;
     box-shadow: none !important;
     font-family: var(--font-family) !important;
     font-weight: 500 !important;
     color: #000 !important;
     background-color: #fff !important;
     border-color: #000 !important;
    
    padding-top: 0.5rem;
        padding-bottom: 0.5rem;
 }

  button.btn-dark.btn.demo-close:hover {
    background-color: #f7f7f7 !important;
       

 } 

 button.demo-send.btn.btn-dark {
     border-radius: 8px !important;
     box-shadow: none !important;
     font-family: var(--font-family) !important;
     font-weight: 500 !important;
    
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #222 !important;
        
 }

  button.demo-send.btn.btn-dark:hover {
    
    background-color: #000 !important;

 } 

 /* ///////////////////////////////////// */

  
div.demo-modal-footer.row.g-3.modal-footer{
    justify-content: center !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
 @media (max-width: 1399.98px) {
    .owl-carousel .owl-item img.p-sl-img-telos {
            max-width: 70% !important;
            height: auto;
            pointer-events: none;
            margin-top: 1.5rem;
    
        }
 .owl-carousel .owl-item img.p-sl-img-ef {
     max-width: 80% !important;
     height: auto;
     pointer-events: none;
     margin-top: 1.5rem;

 }
 }

@media (max-width: 991.98px) {
    #projects {


        padding-bottom: 2rem;


    }

        .p-sl-img{
            padding-top: 1rem;

        }
.p-sl-text .p2{
    margin-bottom: 1rem;
}
.owl-carousel .owl-item img.p-sl-img-telos {
    max-width: 65% !important;
    height: auto;
    pointer-events: none;
    margin-top: 2rem;

}

.owl-carousel .owl-item img.p-sl-img-ef {
    max-width: 75% !important;
    height: auto;
    pointer-events: none;
    margin-top: 2rem;

}

}

@media (max-width: 767.98px) {
   

                .projects-col1 h2 {
                  
        /* fluid-heading-05 lg */
            font-size: 2.625rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.125rem;
        
                }

                                .p-sl-text h4 {
                                  
                                    /* fluid-heading-03 sm */
                                        font-size: 1.25rem;
                                        font-weight: 500;
                                        letter-spacing: 0;
                                        line-height: 1.75rem;
                
                                }
                                                .p-sl-text p {
                                                   
                        
                                                /* body-02 */
                                                    font-size: 1rem;
                                                    font-weight: 400;
                                                    letter-spacing: 0;
                                                    line-height: 1.5rem;
                        
                        
                                                }
                                                        .p-sl-text .p2 span a {
                                                           
                            
                                                            /* body-02 */
                                                                font-size: 1rem;
                                                                font-weight: 500;
                                                                letter-spacing: 0;
                                                                line-height: 1.5rem;
                                                           
                            
                                                        }
            
                      .owl-carousel .owl-item img.p-sl-img-telos {
                          max-width: 65% !important;
                         height: auto;
                          pointer-events: none;
                          margin-top: 1.5rem;
                                                        
                          }
                                 .owl-carousel .owl-item img.p-sl-img-ef {
                                     max-width: 75% !important;
                                     height: auto;
                                     pointer-events: none;
                                     margin-top: 1.5rem;
                
                                 }
                
}
