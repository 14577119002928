* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
}
.nowrap{
  white-space: nowrap;
}


.gradient-text {
  background: var(--gradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-test-logo-text {
  background: var(--gradient-test-logo-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
