 #jobSingle {
     padding-top: 0rem !important;
     text-align: left !important;
 }

 .jobSingle-container {
     
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
    /* padding-left:1rem !important;
     padding-right: 1rem !important;  */
 } 

 .jobSingle-section-main {

     display: flex !important;
     flex-direction: row !important;
     flex-wrap: wrap !important;
     justify-content: center !important;
     align-items: center !important;
     /* padding: 1rem 2rem 1rem 2rem !important; */
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
 }

 .jobSingle-main-title {
     display: flex !important;
     flex-direction: column !important;
     flex-wrap: wrap !important;
     align-content: flex-start !important;
     align-items: center !important;

     padding-top: 1rem !important;
        padding-bottom: 1rem !important; 
 }

 .jobSingle-main-title h2 {
    
    padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
     margin: 0 !important;
    font-family: var(--font-family) !important;
     color: #000 !important;


    /* fluid-paragraph-01 sm */
        font-size: 1.4rem !important;
        font-weight: 500 !important;
        letter-spacing: 0 !important;
        line-height: 1.875rem !important;

 }

 .jobSingle-section-main-bottom {

     display: flex !important;
     flex-direction: row !important;
     flex-wrap: wrap !important;
     justify-content: center !important;
     align-items: center !important;
     /* padding: 0rem 2rem 2rem 2rem !important; */
    
        padding-bottom: 2rem !important;
 }

 .jobSingle-main-title-bottom {
     display: flex !important;
     flex-direction: column !important;
     flex-wrap: wrap !important;
     align-content: center !important;
     align-items: center !important;

     padding: 0 !important;
 }

 

 .jobSingle-after-text-row {
     display: flex !important;
     flex-direction: row !important;
     justify-content: flex-start !important;
     /* padding: 1rem 2rem 1rem 2rem !important; */
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;

 }



 .jobSingle-text-main {
     display: flex !important;
     flex-direction: row !important;
     /* padding: 1.5rem 2rem 0.5rem 2rem !important; */
     justify-content: center !important;
    padding-top: 1.5rem !important;
        padding-bottom: 0.5rem !important;

 }



 .jobSingle-text-right {
     display: flex !important;
     flex-direction: column !important;

     /* padding: 1rem 0rem 1rem 0rem !important; */
     background: #fff !important;
    padding-top: 1rem !important;
        padding-bottom: 1rem !important;
 }

 .jobSingle-col {
     display: flex !important;
     flex-direction: column !important;
     flex-wrap: wrap !important;
     align-items: flex-start !important;
     /* padding: 0.5rem !important; */
    padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;

 }

 .jobSingle-col-p {
     margin: 0 !important;
     /* font-size: 1.2rem !important;
     font-weight: 300 !important; */
     color: #000 !important;
    
    font-family: var(--font-family) !important;
     padding-bottom: 0.3rem !important;
   

    /* fluid-quotation-01 sm */
        font-size: 1.25rem !important;
        font-weight: 400 !important;
        letter-spacing: 0 !important;
        line-height: 1.625rem !important;

        text-align: start !important;
    

 }

 .jobSingle-col h3 {
     /* font-size: 1.2rem !important;
     font-weight: 500 !important; */
     color: #000 !important;
    font-family: var(--font-family) !important;
    /* fluid-quotation-01 sm */
        font-size: 1.25rem !important;
        font-weight: 500 !important;
        letter-spacing: 0 !important;
        line-height: 1.625rem !important;

 }

 .jobSingle-col ul {
     list-style-type: square !important;
 }

 .jobSingle-col ul li {
     /* font-size: 1.2rem !important;
     font-weight: 300 !important; */
     color: #000 !important;
    
    font-family: var(--font-family) !important;
    /* fluid-quotation-01 sm */
        font-size: 1.25rem !important;
        font-weight: 400 !important;
        letter-spacing: 0 !important;
        line-height: 1.625rem !important;

 }

 .jobSingle-col ul li::marker {
     font-size: 1rem !important;

 }

 .jobSingle-after-logo {
     display: flex !important;
     flex-direction: row !important;
     justify-content: flex-start !important;

     /* padding: 1rem 2rem 1rem 2rem !important; */
    padding-top: 1rem !important;
        padding-bottom: 1rem !important;
 }

 .jobSingle-after-logo-text {
     display: flex !important;
     flex-direction: column !important;
     align-items: flex-start !important;
     /* padding: 0.5rem !important; */
    padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
 }

 /* .jobSingle-btn {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
     
     padding: 0.5rem 1rem 0.5rem 1rem !important;
    
     border-radius: 8px !important;
     text-align: center !important;
     
     color: #fff !important;
     background-color: #222 !important;
     
     cursor: pointer !important;
     font-size: 0.9rem !important;
   
     font-weight: 600 !important;
     
    font-family: var(--font-family) !important;
 }

 .jobSingle-btn p {
     font-size: 0.9rem !important;
   
     font-weight: 600 !important;
     margin: 0 !important;
     
     color: #fff !important;
     
    
    font-family: var(--font-family) !important;
     padding: 0rem 1rem !important;
    
 }

 .jobSingle-btn p a {
     text-decoration: none !important;
     
     color: #fff !important;
 }

 .jobSingle-btn:hover {
    

     background-color: #000 !important;


 } */

 .jobSingle-btn-new {
     box-shadow: none !important;

     border-color: #222 !important;
     color: #fff !important;
     background-color: #222 !important;
     border-width: 1px !important;
     border-style: solid #222 !important;
     height: max(3rem, 48px) !important;
     border-radius: max(1.5rem, 24px) !important;
     display: flex !important;
     width: max-content !important;
     align-items: center !important;
     justify-content: center !important;
     text-decoration: none !important;
     line-height: 1 !important;
     cursor: pointer !important;

     padding: 0 24px !important;
     font-family: var(--font-family) !important;

     font-size: 0.9rem !important;

     font-weight: 600 !important;


     transition:
         background-color .3s ease-in-out,
         color .3s ease-in-out,
         border-color .3s ease-in-out,
         border-style .3s ease-in-out;

 }

 .jobSingle-btn-new:hover {
     background-color: #000 !important;
     border-color: #000 !important;
     border-style: solid #000 !important;
     color: #ffffff !important;

 }

 @media (max-width: 991.98px) {
     .jobSingle-main-title {
         /* padding: 1rem !important; */
        padding-top: 1rem !important;
            padding-bottom: 1rem !important;
     }

     .jobSingle-text-right {

         /* padding: 1rem !important; */
        padding-top: 1rem !important;
            padding-bottom: 1rem !important;

     }
 }

 @media (max-width: 767.98px) {
    .jobSingle-main-title h2 {
           
            /* fluid-heading-03 sm */
                font-size: 1.25rem !important;
                font-weight: 500 !important;
                letter-spacing: 0 !important;
                line-height: 1.75rem !important;
    
    
        }

                .jobSingle-col-p {
                    
                            /* body-02 */
                            font-size: 1rem !important;
                            font-weight: 400 !important;
                            letter-spacing: 0 !important;
                            line-height: 1.5rem !important;     
        
                }
                .jobSingle-col h3 {
                    /* body-02 */
                        font-size: 1rem !important;
                        font-weight: 500 !important;
                        letter-spacing: 0 !important;
                        line-height: 1.5rem !important;
        
                }
                .jobSingle-col ul li {
                    /* body-02 */
                        font-size: 1rem !important;
                        font-weight: 400 !important;
                        letter-spacing: 0 !important;
                        line-height: 1.5rem !important;
        
                }

 }