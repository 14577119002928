

#nav-bar {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;


}

.navbar {

    padding: 0 !important;
}

.navbar.active {
  
    backdrop-filter: blur(25px) saturate(140%);
        background: rgba(255, 255, 255, 0.85);

}



.navbar-nav>li {
    padding: 0 0.625rem;
}

.navbar-nav li a {
    float: right;
    text-align: left;
    font-family: var(--font-family);
    font-weight: 400 !important;
   

}

.navbar-light .navbar-nav .nav-link {
   
    font-size: 1rem !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
     color: #000000 !important;
     text-decoration: none;
    transition: text-decoration .3s ease-in-out;
   

    
}
.navbar-light .navbar-nav .nav-link:hover{
     text-decoration: underline #000000;
    text-underline-offset: 4px;
    
}

/* li.nav-item {
    border: 0;
     color: rgba(0, 0, 0, .55) !important; 
     color: #000000 !important; 
    transition: color .3s ease-in-out;
}
li.nav-item:hover{
    color: #212529 !important;
} */

a.nav-link {
    border: 0;
   cursor: pointer;
}


.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.navbar-light .navbar-toggler {
    border-color: rgb(0, 0, 0, 0.0);
}




ul.nav-menu-new {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: center;
    margin-right: 2rem;
}

li.nav-item-new {
    height: 80px;

}

a.nav-links-new {
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: var(--font-family);
    font-weight: 400 !important;
    
    cursor: pointer;

}

.menu-icon-new {
    display: none;
}

@media screen and (max-width: 1000px) {
    li.nav-items-new {
        position: relative;
    }

    ul.nav-menu-new {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 80.83px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

    }

    ul.nav-menu-new.active {
        
        background: #fff;
        

        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    ul.nav-menu-new.active {
        padding: 0rem 0 0 0;

    }

    a.nav-links-new {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        font-family: var(--font-family);
    font-weight: 500 !important;
        font-size: 1rem;
        text-decoration: none;
        transition: text-decoration .3s ease-in-out;

    }


    a.nav-links-new:hover {

        text-decoration: underline #000000;
            text-underline-offset: 4px;
        
    }

    .menu-icon-new {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

}

