.navbar-brand2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0rem 0.5rem 0rem;;
    /* padding: 0.5rem 0rem 0.5rem 0rem; */

}

.nav-logo2-content {

    display: flex;
    flex-direction: row;
     align-items: center; 
    
    padding: 0rem;
    
}



.nav-logo2-img {

    height: auto;
    width: 150.7px;

}

a.navbar-brand2 {
    cursor: pointer;
    text-decoration: none !important;
}

@media (max-width: 991.98px) {
    .navbar-brand2 {
          
            padding: 0.9rem 0rem 0.5rem 0rem;
    
        }
}