  #team{
      background: #fff;  
     padding-top: 0rem; 
      
  }
  .team-main{
    margin-top: 3rem;
      margin-bottom: 4.5rem;
      
  }
  
  .team-title-main{
    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 2rem 2rem 4rem 2rem;
  }
  .team-title{
    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0;
  }
 
  .team-title p img{
    
        width: 4.5rem;
        height: auto;
        pointer-events: none;
    
      
  }
  
  .team-content .team-name{
    font-family: var(--font-family);
    
    color: #000000;

    
       font-weight: 500 !important; 
      font-size: 15.9px;
  }
  .team-content .team-position{
    font-family: var(--font-family);
        color: #000000;
        font-weight: 400 !important;
       font-size: 15.9px;
       
  }
  
    

 .team-filter-img {
    max-width: 82%;
    height: auto;
    pointer-events: none;
    border-radius: .75rem;
    
}


@media (max-width: 991.98px) {
  .team-main {
      
      margin-bottom: 1.5rem;
  
    }
}



