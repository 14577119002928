

#contact {
    
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

        background-color: #f7f7f7;
        
   
}

 .footer2-main {
   
    padding-top: 2rem;
    padding-bottom: 0.2rem;
   
} 


hr {
    background: rgba(0, 0, 0, 0.3);
}

.copyright {
    margin-bottom: 0;
    padding-bottom: 1.25rem;
    text-align: center;
}


.footer2_section__padding {
    
    padding-top: 4rem;
    padding-bottom: 1rem;
}


.footer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.footer2-heading {
    width: 100%;
     text-align: center; 

     margin-bottom: 3rem; 
}

.footer2-heading h1 {
   
        /* fluid-heading-05 xlg */
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.5rem;
    
     /* line-height: 75px;  */
    font-family: var(--font-family);
    
    color: #000;
    margin: 0;
   margin-bottom: 2rem;

    
}

.footer2-heading p {

        font-size: 1.25rem;
        letter-spacing: 0;
        line-height: 1.625rem;
    
    font-weight: 500;
     /* line-height: 32px;  */
    font-family: var(--font-family);
    
    color: #000;
    margin: 0;
    padding: 2rem 0 0.5rem 0;
   
}


.footer2-btn-new {
    background-color: rgba(15, 98, 254, 0.9);
    border-color: rgba(15, 98, 254, 0.9);
    color: #fff;
    border-width: 1px;
    border-style: solid rgba(15, 98, 254, 0.9);
    height: max(3rem, 48px);
    border-radius: max(1.5rem, 24px);
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    margin-bottom: 10rem;
    padding: 0 24px;
    font-family: var(--font-family);

/* heading-02 */
font-size: 0.9rem;
line-height: 1.5rem;
letter-spacing: 0;
font-weight: 500;
    text-transform: uppercase;

    transition:
        background-color .3s ease-in-out,
        color .3s ease-in-out,
        border-color .3s ease-in-out,
        border-style .3s ease-in-out;

}

.footer2-btn-new:hover {
    background-color: #0F62FE;
    border-color: #0F62FE;
    border-style: solid #0F62FE;
    color: #ffffff;


}

.footer2-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
    font-family: var(--font-family);
    color: #000;

}

.footer2_links-two-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
     align-items: flex-end; 
    text-align: left;
    font-family: var(--font-family);
    color: #000;
}


.footer2-links_logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-family: var(--font-family);
        color: #000;
        width: 255px;
       
}



.footer2-links_logo .footer2-paragraf {

font-size: 0.9rem;
   
    font-weight: 400;
    
    color: #000;
    font-family: var(--font-family);
    
    margin: 0.5rem 0 0 0;
   
}
.footer2-paragraf .nowrap{
    white-space: nowrap;
}

/* //////////////////////////////////////////////////////////////////////////////////////// */
.footer2-links_div1{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

        font-family: var(--font-family);
        color: #000;
        width: 255px;

}

.footer2-links_div1 h4 {
    font-size: 1rem;
    line-height: 17px;

    color: #000;

    margin-bottom: 0;
    font-family: var(--font-family);
    font-weight: 400;

}

.footer2-links_div1 p {
   
    color: #000;
    margin-bottom: 0;
    margin-left: 0.3rem;
}

.footer2-links_div2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column; 
    margin-top: 2rem;
        text-align: left;
        font-family: var(--font-family);
        color: #000;
        width: 255px;
    
}

.footer2-links_div2 h4 {
    font-size: 1rem;
    line-height: 17px;
    color: #000;
    font-family: var(--font-family);
    font-weight: 400;
    
}

.footer2-links_div2 p {
    font-size: 0.8rem;
    line-height: 15px;
    color: #000;
    margin: 1rem 0 0 0;
    font-family: var(--font-family);
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* .footer2-social{
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       
}

.footer2-social .p-l-social{
    margin-right: 0.3rem;
        margin-bottom: 0.6rem;
} */



.footer2-copyright {
    margin-top: 2rem;
    text-align: left;
    width: 100%;


}

.footer2-copyright p {
    font-size: 0.8rem;
    line-height: 15px;
    color: #000;
    font-family: var(--font-family);
  
    
}


 .footer2-logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
}


.footer2-logo-wrapper .footer2-logo-img {
   

    height: auto;
    width: 150.7px;
}


.footer2-link a {
    text-decoration: none;
    font-size: 0.9rem;
       
    font-weight: 400;
    color: #000;
    font-family: var(--font-family);
    transition: color .3s ease-in-out;
    
}

.footer2-link a:hover {
    text-decoration: underline;
    
    color: #0F62FE;
}

@media screen and (max-width: 991.98px) {
   

        .footer2_links-two-right{
             flex-direction: column-reverse;
             justify-content: flex-start; 
            
            
        }


}

@media screen and (max-width: 767.98px) {
.footer2-heading h1 {

        /* fluid-heading-05 lg */
            font-size: 2.625rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.125rem;

    }

        .footer2-heading p {
    
    
            /* body-02 */
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.5rem;
    
        }
    

    .ponos__footer2-links div {
        margin: 1rem 0;
    }

    .footer2-btn p {
        font-size: 0.8rem;
        line-height: 20px;
        
    }

}

@media screen and (max-width: 575.98px) {
   
        .footer2-heading p {
            padding: 2rem 0rem 0.5rem 0rem;

            
        }

}