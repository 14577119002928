.hiring-main {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0.75rem;
        padding-right: 0.75rem;

}

.hiring-row1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    
    padding-top: 1rem;
    padding-bottom: 2rem;

}

.hiring-col1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
   
    padding-top: 3rem;
    padding-bottom: 3rem;


}

.hiring-col1 h2 {
    margin: 0;
   
    font-family: var(--font-family);

    color: #000;
           /* fluid-heading-05 xlg */
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.5rem;

}


.open-position {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    padding-top: 0.5rem;
    padding-bottom: 2rem;

}
.hiring1-open-position {
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* padding-top: 1rem; */
    /* padding-bottom: 2rem; */

}

.open-position-row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    
    
}
.hiring1-open-position-row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;


}
.open-position-row2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.open-position-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
        align-items: flex-start;
       

    color: #000;

}
.hiring1-open-position-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    color: #000;

}

.open-position-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    color: #000;
    

}
.hiring1-open-position-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #000;


}

.open-position-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

}

.hiring2-open-position-link span {
    cursor: pointer;
    text-align: left;

    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        
        letter-spacing: 0;
        line-height: 1.625rem;
        font-weight: 500;
        color: #000;
        font-family: var(--font-family);

    margin: 0rem 0rem 1rem 0rem;

}

.hiring2-open-position-link span a {
    text-decoration: underline;
    color: #000;
    transition: background-color .3s ease-in-out;


}

.hiring2-open-position-link span a:hover {
    /* color: #0F62FE; */
background-color: #f7f7f7;

}
.open-position-header h4 {
    margin: 0;
    font-family: var(--font-family);

    color: #000;

        /* fluid-paragraph-01 sm */
            font-size: 1.5rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.875rem;
    
    padding-top: 0.5rem;
    padding-bottom: 1rem;
   

}
.hiring1-open-position-header h4 {
    margin: 0;
    font-family: var(--font-family);

    color: #000;

    /* fluid-paragraph-01 sm */
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.875rem;

    /* padding-top: 0.5rem;
    padding-bottom: 1rem; */
    margin-top: 0.5rem;
    margin-bottom: 2rem;


}



.open-position-main p {

    margin-bottom: 1rem;
    /* color: #0F62FE; */
     color: #000; 
    font-family: var(--font-family);

    /* fluid-paragraph-01 sm */
    font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.875rem;

}

.hiring1-open-position-main p {

    margin-bottom: 2rem;
    /* color: #0F62FE; */
    color: #000;
    font-family: var(--font-family);

    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.625rem;
        text-align: center;

}


.open-position-footer img {

    /* width: 35%; */
    width: 40%;
    height: auto;
    pointer-events: none;
   
    /* margin-top: 3rem;
    margin-bottom: 3rem; */
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.open-position-footer p {

    margin-bottom: 4rem;
    
    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.625rem;
    
    color: #000;
    font-family: var(--font-family);

    text-align: center;

}

/* .hiring-main-title {
    position: relative;
}

.hiring-main-title::after {
    position: absolute;
    top: 3.6rem;
    left: 0rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 4.5rem;
    background-color: rgba(0, 0, 0);
}

.hiring-main-title:hover::after {
    -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;


} */

.nowrap {
    white-space: nowrap;
}

.modal-body {
    padding: 0 !important;
}

.modal-header {
    border-bottom: none !important;
}

.modal-header .btn-close {
    box-shadow: none !important;
}

.modal-footer {
    border-top: none !important;
}

.modal-title {
    /* font-size: 1.1rem !important;
    font-weight: 500 !important; */
    color: #000 !important;

    font-family: var(--font-family) !important;
    /* fluid-quotation-01 sm */
        font-size: 1.25rem !important;
        font-weight: 500 !important;
        letter-spacing: 0 !important;
        line-height: 1.625rem !important;
}

button.h-primary.btn.btn-primary {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border-color: #000000 !important;
    border-style: solid !important;
    color: #000 !important;
    font-family: var(--font-family) !important;
    font-weight: 600 !important;
    border-width: 1px !important;
    height: max(3rem, 48px) !important;
    border-radius: max(1.5rem, 24px) !important;

    display: flex !important;
    width: max-content !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    line-height: 1 !important;
    cursor: pointer !important;
    padding: 0 24px !important;
    margin-bottom: 1rem !important;

    transition:
        background-color .3s ease-in-out,
        color .3s ease-in-out,
        border-color .3s ease-in-out;
}

button.h-primary.btn.btn-primary:hover {

    background-color: #f7f7f7 !important;
    border-color: #000000 !important;
    color: #000 !important;

}

button.h-secondary.btn.btn-secondary {

    box-shadow: none !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    color: #000 !important;
    background-color: #fff !important;
    border-color: #000 !important;

    border-style: solid !important;
    border-width: 1px !important;
    height: max(3rem, 48px) !important;
    border-radius: max(1.5rem, 24px) !important;
    display: flex !important;
    width: max-content !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    line-height: 1 !important;
    cursor: pointer !important;
    padding: 0 24px !important;

    transition:
        background-color .3s ease-in-out,
        color .3s ease-in-out,
        border-color .3s ease-in-out;
}

button.h-secondary.btn.btn-secondary:hover {
    background-color: #f7f7f7 !important;
    border-color: #000 !important;
    color: #000 !important;

}


.btn-apply {
    box-shadow: none;
    background-color:#ffffff;
    border-color: #ffffff;
    border-style: solid #ffffff;
    font-family: var(--font-family);
    color: #000;
    border-width: 1px;
    height: max(3rem, 48px) ;
    border-radius: max(1.5rem, 24px);
    display: flex ;
    width: max-content;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    line-height: 1 ;
    cursor: pointer ;
    padding: 0 24px ;
    margin-bottom: 1rem ;
    margin-left: -24px;
    margin-right: -24px;

    /* fluid-quotation-01 sm */
        font-size: 1.25rem;
        letter-spacing: 0;
        line-height: 1.625rem;
        font-weight: 500;

    transition:
        background-color .3s ease-in-out,
        color .3s ease-in-out,
        border-color .3s ease-in-out,
        border-style .3s ease-in-out;
}

.btn-apply:hover {

    background-color: #F7F7F7;
    border-color: #F7F7F7;
    border-style: solid #F7F7F7;
    color: #000;

}

@media (max-width: 991.98px) {

    .open-position-header h4 {
        margin-bottom: 1rem;


    }
         .hiring1-open-position-header h4 {
             margin-bottom: 1rem;
    
    
         }


    .open-position-footer p {

        margin-bottom: 2rem;


    }

    .open-position-main p {

        margin-bottom: 1.5rem;


    }
        .hiring1-open-position-main p {
    
            margin-bottom: 1.5rem;
    
    
        }

    .button.h-primary.btn.btn-primary {
        margin-bottom: 2rem !important;

    }

    .open-position-footer img {
        /* margin-top: 1rem;
        margin-bottom: 1rem; */

        margin-top: 2rem;
            margin-bottom: 2rem;

        /* width: 50%; */
        width: 55%;
        height: auto;



    }


}

@media (max-width: 767.98px) {
    .btn-apply{
        padding: 0 12px;
            margin-left: -12px;
            margin-right: -12px;
    }
    .hiring-col1 h2 {
    
            /* fluid-heading-05 lg */
            font-size: 2.625rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 3.125rem;
    
        }
                .hiring2-open-position-link span {
                  
                /* body-02 */
                    font-size: 1rem;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 1.5rem;
        
                   
        
                }

                .btn-apply{
                    /* body-02 */
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 1.5rem;
                }
                .open-position-header h4 {
                /* fluid-paragraph-01 sm */
                    font-size: 1.5rem;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 1.875rem;
        
        
                }
                                .hiring1-open-position-header h4 {
                                    /* fluid-paragraph-01 sm */
                                    font-size: 1.5rem;
                                    font-weight: 500;
                                    letter-spacing: 0;
                                    line-height: 1.875rem;
                
                
                                }
                .open-position-main p {
        
                   
                /* fluid-quotation-01 sm */
                     font-size: 1.25rem;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 1.625rem; 
        
                }
                                .hiring1-open-position-main p {
                
                
                                    /* fluid-quotation-01 sm */
                                    font-size: 1.25rem;
                                    font-weight: 400;
                                    letter-spacing: 0;
                                    line-height: 1.625rem;
                
                                }
        .open-position-footer p {
    
          
        /* body-02 */
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.5rem;
    
           
    
        }
        .modal-title {
            /* body-02 */
                font-size: 1rem !important;
                font-weight: 500 !important;
                letter-spacing: 0 !important;
                line-height: 1.5rem !important;
        }
}




@media (max-width: 575.98px) {
    .open-position-header {
           
            align-items: center;
    
        }
        .hiring1-open-position-header {
    
            align-items: center;
    
        }

    .open-position-footer img {

        /* width: 65%; */
        width: 75%;
        height: auto;
       
    }
}