#products {
    padding-top: 3rem;
    padding-bottom: 4.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.main-products {
    padding-bottom: 0.5rem;

}

.products-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    padding-bottom: 1rem;

}

.products12 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background: #fff;
    padding-top: 3rem;
    padding-bottom: 3rem;


}

.products12 h2 {
    margin: 0;
    font-family: var(--font-family);

    color: #000;
    /* fluid-heading-05 xlg */
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 3.5rem;


}

/* .products-main-title {
    position: relative;
}

.products-main-title::after {
    position: absolute;
    
    top: 3.6rem;
    left: 0rem;
    content: '';
    display: block;
    
    height: 0.25rem;
    width: 4.5rem;
    background-color: rgba(0, 0, 0);
}

.products-main-title:hover::after {
    -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;


} */

.products-text-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    padding-top: 3rem;
    padding-bottom: 3rem;


}

.products-bottom-line {
    border-bottom: 0.5px solid rgba(0, 0, 0, .2);
}


.products-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;


}

.products-col-img {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;


}

.products-col-img .products-img-paas {

    /* width: 65%; */
    width: 50%;
    height: auto;
    pointer-events: none;


}

.products-col-img .products-img-sbp {

    /* width: 65%; */
    width: 98%;
    height: auto;
    pointer-events: none;

}


.products-col h4 {
    margin: 0;
    font-family: var(--font-family);
    color: #000;
    text-transform: uppercase;


    /* fluid-paragraph-01 sm */
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.875rem;

    padding-top: 1rem;
    padding-bottom: 1rem;


}

.products-col p {
    margin: 0;


    /* fluid-quotation-01 sm */
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.625rem;

    color: #000;
    font-family: var(--font-family);

    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;



}

@media (max-width: 991.98px) {
    #services {

        padding-bottom: 0rem;
    }
}




@media (max-width: 767.98px) {

    .products-column-reverse {
        flex-direction: column-reverse;
    }

    .products12 {
        text-align: left;

    }


    .products-text-main {
        justify-content: center;

        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .products12 h2 {

        /* fluid-heading-05 lg */
        font-size: 2.625rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 3.125rem;


    }

    .products-col h4 {

        /* fluid-heading-03 sm */
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.75rem;

    }

    .products-col p {


        /* body-02 */
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5rem;

    }

        
    .products-img-paas,
    .products-img-sbp {
        margin-bottom: 1.5rem;
    }



}

@media (max-width: 575.98px) {
    .products-col {
        align-items: center;

    }
}